import request from '@/utils/request'


// 查询呼叫记录列表
export function listRecord(query) {
	return request({
		url: '/call/call-record/list',
		method: 'get',
		params: query
	})
}

// 查询呼叫记录分页
export function pageRecord(query) {
	return request({
		url: '/call/call-record/page',
		method: 'get',
		params: query
	})
}

// 查询呼叫记录详细
export function getRecord(data) {
	return request({
		url: '/call/call-record/detail',
		method: 'get',
		params: data
	})
}

// 新增呼叫记录
export function addRecord(data) {
	return request({
		url: '/call/call-record/add',
		method: 'post',
		data: data
	})
}

// 修改呼叫记录
export function updateRecord(data) {
	return request({
		url: '/call/call-record/edit',
		method: 'post',
		data: data
	})
}

// 删除呼叫记录
export function delRecord(data) {
	return request({
		url: '/call/call-record/delete',
		method: 'post',
		data: data
	})
}

// 报销类型列表
export function reimbursement(data) {
	return request({
		url: '/reimbursement/reimbursement-type/page',
		method: 'get',
		params: data
	})
}

// 查询审批分页
export function approval(data) {
	return request({
		url: '/approval/approval/page',
		method: 'get',
		params: data
	})
}

// 查询审批分页
export function signature(data) {
	return request({
		url: '/common/get-oss-signature',
		method: 'get',
		params: data
	})
}

// 新增审批
export function approvalAdd(data) {
	return request({
		url: '/approval/approval/add',
		method: 'post',
		data: data
	})
}

// 获取审批详情
export function approvalEdit(data) {
	return request({
		url: '/approval/approval/edit',
		method: 'post',
		data: data
	})
}

// 获取审批详情
export function approvalDetail(data) {
	return request({
		url: '/approval/approval/detail',
		method: 'get',
		params: data
	})
}

// 区域列表
export function regionList(data) {
	return request({
		url: '/system/dept/getRegionList',
		method: 'get',
		params: data
	})
}


// 区域团队列表
export function areaTeam(data) {
	return request({
		url: '/system/user/deptTree',
		method: 'get',
		params: data
	})
}

// 区域团队列表
export function getTeamList(data) {
	return request({
		url: '/system/dept/getTeamList',
		method: 'get',
		params: data
	})
}

// 批量查询部门下用户列表
export function getByDeptIds(data) {
	return request({
		url: '/system/user/getByDeptId',
		method: 'get',
		params: data
	})
}


// 删除审批
export function approvalDelete(data) {
	return request({
		url: '/approval/approval/delete',
		method: 'post',
		data: data
	})
}